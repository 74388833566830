import Price from './price'
import Unit from './unit'

const PriceAndUnit = (props) => (
    <>
        <Price {...props} /> <Unit {...props} />
    </>
)

export default PriceAndUnit
