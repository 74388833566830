import React from 'react'
import { ProductLocation } from '@zupr/types/fo'

import Tooltip from '../../../components/tooltip'
import { useShoppingListItem } from '../../../../frontoffice/features/shopping-list/hooks/item'

import { ReactComponent as BikerIcon } from '../../../../../svg/biker.svg'
import { ReactComponent as ReservationIcon } from '../../../../../svg/reservation.svg'
import { ReactComponent as PayAndCollectIcon } from '../../../../../svg/pay-and-collect.svg'
import { ReactComponent as WarehouseIcon } from '../../../../../svg/warehouse.svg'
import { ReactComponent as CallForStockIcon } from '../../../../../svg/call-for-stock.svg'
import { ReactComponent as InventoryCheckIcon } from '../../../../../svg/inventory-check.svg'

import '../../../../../scss/react/components/stock-indicator.scss'

export const ResevationIndicator = () => (
    <Tooltip label="Reserveren" className="stock-indicator">
        <ReservationIcon className="stock-icon reservation" />
    </Tooltip>
)

export const PickupIndicator = () => (
    <Tooltip label="Afhalen" className="stock-indicator">
        <PayAndCollectIcon className="stock-icon pay-and-collect" />
    </Tooltip>
)

export const DeliveryIndicator = () => (
    <Tooltip label="Laten bezorgen" className="stock-indicator">
        <BikerIcon className="stock-icon deliverable" />
    </Tooltip>
)

interface IconProps {
    stock_prefs: ProductLocation['stock_prefs']
    stock_count: ProductLocation['stock_count']
    active: ProductLocation['active']
}

export const IconOnly = ({ stock_prefs, stock_count, active }: IconProps) => {
    if (active === false) {
        return <WarehouseIcon className="stock-icon not-available" />
    }

    switch (stock_prefs) {
        case 'in_stock':
            return <WarehouseIcon className="stock-icon available" />
        case 'exact_stock':
            if (stock_count === 0 || !stock_count)
                return <WarehouseIcon className="stock-icon not-available" />
            return <WarehouseIcon className="stock-icon available" />

        case 'not_in_stock':
            return <WarehouseIcon className="stock-icon not-available" />
        case 'call_for_stock':
            return <InventoryCheckIcon className="stock-icon call-for-stock" />
        default:
            return <CallForStockIcon className="stock-icon call-for-stock" />
    }
}

export const Icon = ({ stock_prefs, stock_count, active }: IconProps) => {
    if (active === false) {
        return (
            <Tooltip label="Not in stock" className="stock-indicator">
                <WarehouseIcon className="stock-icon not-available" />
            </Tooltip>
        )
    }

    switch (stock_prefs) {
        case 'in_stock':
            return (
                <Tooltip label="In stock" className="stock-indicator">
                    <WarehouseIcon className="stock-icon available" />
                </Tooltip>
            )
        case 'exact_stock':
            if (stock_count === 0 || !stock_count)
                return (
                    <Tooltip label="Not in stock" className="stock-indicator">
                        <WarehouseIcon className="stock-icon not-available" />
                    </Tooltip>
                )
            return (
                <Tooltip
                    label="%{stock_count} in stock"
                    values={{ stock_count }}
                    className="stock-indicator"
                >
                    <WarehouseIcon className="stock-icon available" />
                </Tooltip>
            )

        case 'not_in_stock':
            return (
                <Tooltip label="Not in stock" className="stock-indicator">
                    <WarehouseIcon className="stock-icon not-available" />
                </Tooltip>
            )
        case 'call_for_stock':
            return (
                <Tooltip
                    label="Availability upon request"
                    className="stock-indicator"
                >
                    <InventoryCheckIcon className="stock-icon call-for-stock" />
                </Tooltip>
            )
        default:
            return (
                <Tooltip
                    label="Availability upon request"
                    className="stock-indicator"
                >
                    <CallForStockIcon className="stock-icon call-for-stock" />
                </Tooltip>
            )
    }
}

export const InteractiveIcon = (productLocation) => {
    const { partOfInformationRequest, isRequesting, isAnswered, stockCount } =
        useShoppingListItem(productLocation)

    if (partOfInformationRequest && isRequesting) {
        return (
            <span className="stock-indicator waiting-for-answer">
                <span className="loading" />
            </span>
        )
    }

    if (partOfInformationRequest && isAnswered) {
        return (
            <Icon
                {...productLocation}
                stock_prefs="exact_stock"
                stock_count={stockCount}
            />
        )
    }

    return <Icon {...productLocation} />
}

const ProductLocationIcon = (productLocation) => {
    if (productLocation.location)
        return <InteractiveIcon {...productLocation} />
    return <Icon {...productLocation} />
}

export default ProductLocationIcon
