import React from 'react'
import { t } from '@zupr/i18n'
import { ProductLocation } from '@zupr/types/fo'

export interface UnitProps {
    unit: ProductLocation['unit']
    unit_amount: ProductLocation['unit_amount']
}

export const UnitText = ({ unit, unit_amount }: UnitProps) => {
    if (!unit_amount) return null
    return t(
        `per ${unit} |||| per %{amount} ${unit}`,
        { smart_count: unit_amount, amount: unit_amount },
        'unit'
    )
}

const Unit = ({ unit, unit_amount }: UnitProps) => {
    if (!unit_amount) return null
    return (
        <span className="product-location-unit">
            <UnitText unit={unit} unit_amount={unit_amount} />
        </span>
    )
}

export default Unit
