import {
    Product,
    Location,
    ShoppingList,
    ShoppingListItem,
    ProductLocation,
} from '@zupr/types/fo'

import { useCallback, useContext } from 'react'

import ShoppingListContext from '../../../../context/shopping-list'

interface UseProductInformationRequestProps {
    product: Product | ProductLocation['product']
    location: Location
}

export const useProductInformationRequest = ({
    product,
    location,
}: UseProductInformationRequestProps) => {
    const { lists } = useContext(ShoppingListContext)

    if (!product || !location) return null

    // find list
    const list = lists.find(({ locationId }) => location?.id === locationId)

    if (!list || !list.informationRequest) return null

    // find product
    const information = list.informationRequest.productInformationRequests.find(
        ({ productId }) => product?.id === productId
    )

    return information || null
}

interface UseItemProps {
    list: ShoppingList
    item?: ShoppingListItem
    productLocation?: ProductLocation
}

export const useShoppingAddProductLocation = (
    productLocation: ProductLocation
) => {
    const { lists, updateList, createList } = useContext(ShoppingListContext)

    // find list and add item
    const addItem = useCallback(() => {
        const list =
            lists.find(
                ({ locationId }) => locationId === productLocation.location.id
            ) || createList(productLocation.location.id)

        const item = {
            productId: productLocation.product.id,
            productLocationId: productLocation.id,
            quantity: 1,
        } as ShoppingListItem

        list.items.push(item)
        updateList({
            ...list,
        } as ShoppingList)
    }, [
        createList,
        lists,
        productLocation.id,
        productLocation.location.id,
        productLocation.product.id,
        updateList,
    ])
    return addItem
}

export interface ExpandedShoppingListItem {
    isOnList: boolean
    onRemove: () => void
    stockCount: number
    availableToAdd: number
    partOfInformationRequest: boolean
    isAnswered: boolean
    isRequesting: boolean
}

export const useExpandedItem = ({
    list,
    item,
    productLocation,
}: UseItemProps): ExpandedShoppingListItem => {
    const { updateList, removeList } = useContext(ShoppingListContext)

    const stockCount = useStockCount(
        item?.productLocation || productLocation || {}
    )

    const availableToAdd = useAvailableToAdd(
        item?.productLocation || productLocation || {}
    )

    const isOnList = !!item
    const { isAnswered, isRequesting } = list || {}

    const partOfInformationRequest = !!(
        isOnList &&
        list.informationRequest?.productInformationRequests?.find(
            ({ productId }) => productId === item.productId
        )
    )

    const onRemove = useCallback(() => {
        if (!item?.productLocationId) return

        list.items = list.items.filter(
            ({ productLocationId }) =>
                item.productLocationId !== productLocationId
        )

        if (!list.items.length) {
            return removeList(list.locationId)
        }

        updateList(list)
    }, [item?.productLocationId, list, removeList, updateList])

    return {
        isOnList,
        onRemove,
        stockCount,
        availableToAdd,
        partOfInformationRequest,
        isAnswered: partOfInformationRequest && !!isAnswered,
        isRequesting: partOfInformationRequest && !!isRequesting,
    }
}

const useStockCount = ({
    stock_prefs,
    stock_count,
    location,
    product,
}: Partial<ProductLocation>) => {
    const information = useProductInformationRequest({ location, product })
    if (information) return information.quantityInStock
    if (stock_prefs && stock_prefs === 'exact_stock') return stock_count
    if (stock_prefs && stock_prefs === 'not_in_stock') return 0
    return null
}

const MAX_TO_ADD = 32

const useAvailableToAdd = ({
    stock_prefs,
    stock_count,
    location,
    product,
}: Partial<ProductLocation>) => {
    const stockCount = useStockCount({
        stock_prefs,
        stock_count,
        location,
        product,
    })
    return stock_prefs && stock_prefs === 'in_stock' ? MAX_TO_ADD : stockCount
}

const useShoppingListForPL = (productLocation) => {
    const { lists } = useContext(ShoppingListContext)

    const list = lists.find(
        ({ locationId }) => productLocation.location.id === locationId
    )

    if (list) return list

    return {
        locationId: productLocation.location.id,
        items: [],
    } as ShoppingList
}

interface UseShoppingListItem
    extends ShoppingListItem,
        ExpandedShoppingListItem {}

export const useShoppingListItem = (
    productLocation: ProductLocation
): UseShoppingListItem => {
    const list = useShoppingListForPL(productLocation)

    if (!productLocation.location) {
        console.warn('no id', productLocation)
    }

    const item = list?.items.find(
        ({ productLocationId }) => productLocation.id === productLocationId
    )

    const expanedItem = useExpandedItem({
        list,
        item,
        productLocation,
    })

    return {
        ...item,
        ...expanedItem,
    }
}
